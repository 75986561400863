
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

:deep(.el-main) {
	// form background color
	background-color: var(--form-background) !important;
}
.form-wrapper {
	:deep(*) {
		font-family: var(--body-font);
		color: var(--body-font-color);
	}
	:deep(.el-form-item__error) {
		color: #ff0000;
	}

	:deep(h2) {
		font-family: var(--headline-font);
		color: var(--headline-font-color);
	}
	:deep(.el-form-item.is-required:not(.is-no-asterisk)
			> .el-form-item__label:before) {
		color: #ff0000;
	}

	.pay-method-wrap {
		position: relative;

		.choose-form {
			margin-bottom: 0;
			color: var(--body-font-color);
		}

		.mfes-trustmark {
			right: 7px;
			top: 0;
			position: absolute;
		}
	}

	:deep(.el-input__inner) {
		border-color: var(--body-font-color);
		border: 1px solid;
	}

	:deep(.el-radio, .el-radio:after) {
		border-color: var(--body-font-color);
	}

	:deep(.el-radio__input.is-checked
			+ .el-radio__label, .el-radio__inner:hover) {
		color: var(--body-font-color);
	}

	:deep(.el-textarea__inner) {
		border: 1px solid var(--body-font-color);
	}

	:deep(.el-checkbox__inner) {
		border: 1px solid var(--body-font-color);
	}

	:deep(.el-radio-group
			.el-radio
			.el-radio__input.is-checked
			.el-radio__inner) {
		border: var(--body-font-color);
		background: var(--body-font-color);
	}

	:deep(.el-button--primary.is-disabled) {
		color: var(--body-font-color);
		background-color: var(--bg-color);
		border-color: var(--bg-color);
		opacity: 0.5;
	}

	:deep(.info-icon) {
		border: 1px solid var(--body-font-color);
	}

	:deep(.el-select .el-input .el-select__caret) {
		color: var(--body-font-color);
	}

	:deep(.el-input__inner::-webkit-input-placeholder) {
		color: var(--body-font-color);
		opacity: 0.6;
	}

	// payment button override
	:deep(.el-button--primary:not(.is-plain), .el-button--primary:not(.is-plain):hover, .el-button--primary:not(.is-plain):focus, .el-button--primary:not(.is-plain):active) {
		background-color: var(--bg-color);
		border-color: var(--bg-color);
	}
	:deep(.el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover) {
		color: #fff;
		background-color: var(--bg-color);
		border-color: var(--bg-color);
		opacity: 0.5;
	}
}

#gdpr-opt-out {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 0 10%;
	z-index: 99;
	box-sizing: border-box;
	border-radius: 0;

	:deep(.el-card__body) {
		padding: 25px 20px;
	}

	.el-icon-close {
		display: block;
		font-size: 20px;
		line-height: 1em;
		position: absolute;
		top: 0;
		right: 0;
		color: #888;
		padding: 7px;
		cursor: pointer;

		&:hover {
			color: $blue;
		}
	}

	small {
		display: inline-block;
		width: calc(100% - 215px);
		line-height: 1.25em;
		color: #555;
	}

	.btn-wrap {
		display: inline-block;
		width: 215px;
		box-sizing: border-box;
		padding-left: 40px;
	}

	.el-button {
		width: 80px;
	}

	.reject-btn {
		color: #777;
		margin-right: 5px;

		&:hover,
		&:active,
		&:focus {
			color: #555;
			border-color: #ababab;
			background: #f4f4f5;
		}
	}
}

@media screen and (max-width: 767px) {
	.choose-form {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.mfes-trustmark.loaded {
		top: 18px;
		right: 25px;
		width: 60px;
		height: 25px;
	}
}

@media screen and (max-width: 640px) {
	#gdpr-opt-out {
		padding: 0;

		small {
			display: block;
			width: 100%;
			margin: 5px 0 15px;
		}

		.btn-wrap {
			display: block;
			width: 100%;
			text-align: center;
			padding-left: 0;
			top: 0;
		}

		.el-button {
			width: 100px;
		}
	}
}
