
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

ul {
	list-style: circle;
	list-style-position: inside;
	margin: 20px 0;
	padding: 0;

	li {
		margin: 5px 0;
	}
}

@media screen and (max-width: 600px) {
	.el-button {
		width: 100%;
	}
}
