
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

footer {
	width: 100vw;
	color: rgba(255, 255, 255, 0.5);
	text-shadow: 1px 1px 0px rgb(86 86 86 / 75%);
	text-align: center;
	font-size: 12px;
	font-weight: 200;
	line-height: 1em;
	padding: 40px 0;
	position: relative;
	bottom: 0;

	@media screen and (max-width: 380px) {
		width: 78vw;
	}
}
