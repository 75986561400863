
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

header {
	background: var(--header-color);
	width: 100vw;
	height: auto !important;
	box-shadow: 0px -3px 6px 3px $shadow-gray;

	.header-container {
		max-width: 800px;
		margin: auto;
		display: flex;
		align-items: center;
	}

	.logo-wrapper {
		order: 1;

		img {
			height: 36px;
			margin: 10px 0;
		}

		h1 {
			color: var(--header-font-color);
			display: inline-block;
			white-space: nowrap;
			margin: 0;
			font-size: 16px;
			line-height: 56px;
			letter-spacing: 0.5px;
		}
	}

	.header-text {
		order: 2;
		flex-grow: 1;
		color: var(--header-font-color);
		padding: 1em 0 1em 1.5em;
		text-align: right;
	}

	&.logo-enlarge {
		.logo-wrapper img {
			height: 72px;
		}

		h1 {
			line-height: 92px;
		}
	}
}

@media screen and (max-width: 620px) {
	header {
		height: auto !important;
		text-align: center;

		.header-container {
			flex-flow: column wrap;
		}

		.logo-wrapper h1 {
			font-size: 14px;
			line-height: calc(1em + 40px);
		}

		.header-text {
			padding: 0 0 15px;
			text-align: center;
		}
	}
}
